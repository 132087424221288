import React from 'react'
import PropTypes from 'prop-types'
import Layout from '@components/Layout'
import get from 'lodash/get'
import BottomCta from '@components/BottomCta'
import FeatureOverview from '@components/features/FeatureOverview'
import HelmetHelper from '@components/HelmetHelper'
import PopularQuestionList, {
  createQuestionItem,
} from '@components/custom/PopularQuestionList'
import { Features, Config, Routes } from '@constants'
import Testimonials from '@components/Testimonials'
import Typography from '@atoms/Typography'
import Button from '@atoms/Button'

const POPULAR_QUESTION_ITEMS = [
  createQuestionItem(
    'Is it safe to use a LinkedIn Automation tool?',
    <>
      <div className="mb-3">
        {Config.NAME} combines several security measures to comply with LinkedIn
        rules resulting in a 0% ban rate. With each account, you&apos;ll get
        access to a unique IP address from your region, smart limits, automatic
        warm-up to ensure safety.
      </div>
      <div>
        {Config.NAME} runs safely in the cloud, based on your daily work
        schedule, and mimics human behavior to ensure all LinkedIn actions are
        made naturally without causing suspicion.
      </div>
    </>,
    Features.SAFETY.link
  ),
  createQuestionItem(
    'How many connection requests can I send per week?',
    <>
      <div className="mb-3">
        You can send from 100 invitations on LinkedIn per week, with some
        accounts sending 200-300 invitations per week.
      </div>
      <div>
        However, we recommend gradually scaling up your daily limit by using our
        automated account warm-up and focusing on increasing your acceptance
        rate, as this is a significant factor in how many connection requests
        LinkedIn allows you to send.
      </div>
    </>
  ),
  createQuestionItem(
    'Can I test all the features during the free trial?',
    `Yes, all features are included, so you can test anything you like. You'll get access to a unique IP address from your region to ensure your account security with each account.`
  ),
  createQuestionItem(
    'Will I be charged when my free trial is up?',
    <>
      <div className="mb-3">
        We ask for payment details to start your free trial as a measure to
        prevent fake accounts from abusing LinkedIn automation.
      </div>
      <div>
        We won&apos;t charge your card until the 7-day trial is over. You can
        try us out risk-free.
      </div>
    </>
  ),
  createQuestionItem(
    'Can I manage multiple LinkedIn accounts?',
    `Sure! You can add and manage an unlimited number of LinkedIn accounts. You'll be able to easily switch and share resources between connected LinkedIn accounts.`
  ),
  createQuestionItem(
    `What companies use ${Config.NAME} for LinkedIn automation?`,
    `${Config.NAME} is trusted by Agencies, Sales teams, Growth Hackers, and growing Startups who want to personalize LinkedIn outreach at scale.`
  ),
]

const FreeTrialPage = ({ params }) => {
  const name = get(params, 'slug')

  return (
    <Layout disableLinks>
      <HelmetHelper
        title={`Try ${Config.NAME} | 7-day free trial`}
        description={`Get 10x more replies and personalize outreach at scale with ${Config.NAME}. The safest LinkedIn automation tool that delivers results.`}
      />
      <div className="relative bg-white">
        <div className="pt-16 pb-4 text-center bg-white space-y-12">
          <div className="space-y-5">
            <Typography.H1>
              <span className="capitalize">{name}</span>, enjoy your 7-day trial
            </Typography.H1>
            <Typography.SUBHEADER component="h2">
              Get access to {Config.NAME} for free with all features included.
            </Typography.SUBHEADER>
          </div>

          <div
            className="flex flex-col items-center justify-center space-y-3 mx-auto"
            style={{ width: 'fit-content' }}
          >
            <a
              href={Routes.APP.USER.SIGN_UP}
              draggable="false"
              rel="noopener noreferrer"
              className="relative w-auto"
            >
              <Button size="big" className="w-full md:w-auto">
                Start your free trial
              </Button>
            </a>
            <div className="text-xm text-gray-500">
              <div>No payment required &#183; Cancel anytime</div>
            </div>
          </div>
        </div>

        <Testimonials disableHeader />
        <FeatureOverview />
        <BottomCta />
        <PopularQuestionList list={POPULAR_QUESTION_ITEMS} />
      </div>
    </Layout>
  )
}

FreeTrialPage.propTypes = {
  params: {
    slug: PropTypes.string,
  },
}

FreeTrialPage.defaultProps = {
  params: {
    slug: '',
  },
}

export default FreeTrialPage
